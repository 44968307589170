// import { useAuthStore } from "./auth";
// import { useLocationStore } from "./location";
// import { useSearchStore } from "./search";

// export { useAuthStore, useLocationStore, useSearchStore };

// // You can also add a default export if you prefer
// export default {
//   useAuthStore,
//   useLocationStore,
//   useSearchStore,
// };
import { createPinia } from "pinia";

// Create Pinia instance
const pinia = createPinia();

export default pinia; // Export the configured Pinia instance

// Re-export the individual stores
export { useAuthStore } from "./auth";
export { useLocationStore } from "./location";
export { useSearchStore } from "./search";
