<template>
  <section>
    <!-- Options to toggle allow new tags and open-on-focus -->
    <!-- Tag Input with Auto-suggestions -->
    <b-field label="Let us know what you are looking for">
      <b-taginput
        ref="tagInput"
        v-model="tags"
        :data="filteredTags"
        autocomplete
        :allow-new="allowNew"
        :open-on-focus="openOnFocus"
        placeholder="Iphone 12 Pro Max, 128GB, Blue"
        @typing="onSearch"
        field="term"
        @blur="searchInProgress = false"
        maxlength="100"
        max-tags="10"
        @add="addNewTag"
        size="is-medium"
        @keydown.enter="quickSearch"
      >
        <template v-slot="props">
          {{ props.option.term }}
        </template>
      </b-taginput>
    </b-field>
    <b-button
      @click="quickSearch"
      class="is-primary is-medium"
      :class="{ 'is-loading': searchInProgress }"
    >
      Search
    </b-button>
    <!-- <b-button class="is-info is-medium" @click="updateQuickSearchResults"
      >Update</b-button
    >
    <b-button class="is-warning is-medium" @click="setQuickSearchResults"
      >Set</b-button
    >
    <b-button class="is-warning is-medium" @click="retrieveQuickSearchResults"
      >Retreive</b-button
    >
    <b-button class="is-danger is-medium" @click="deleteQuickSearchResults"
      >Delete</b-button
    > -->

    <!-- Show the currently selected tags -->
    <!-- <pre style="max-height: 400px"><b>Tags:</b>{{ tags }}</pre> -->
  </section>
</template>

<script>
/* eslint-disable */
import { useAuthStore, useLocationStore, useSearchStore } from "@/store";
import { elasticsearchService } from "../services/elasticsearch.service"; // Import your service here
import LoginModal from "./LoginModal.vue";

export default {
  components: {
    LoginModal,
  },
  data() {
    return {
      searchTerm: "", 
      inputText:"",
      inputs:[],// Search input
      suggestions: [], // Array to store suggestions
      searchInProgress: false, // To manage search delay
      tags: [], // Selected tags
      filteredTags: [], // Suggestions based on search input
      allowNew: true, // Allow adding new tags manually
      openOnFocus: false,
      quickSearchResults: null, // Store results from the quick search
    };
  },
  methods: {
    async onSearch(text) {
      if (text.length >= 3) {
        try {
          this.inputText = text;
          this.searchInProgress = true;
          // Fetch suggestions from Elasticsearch using the provided service
          const results = await elasticsearchService.autoSuggestElastic(text);
          const data = results.data.hits.hits.map((hit) => hit._source);
          this.getFilteredTags(text, data);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        this.filteredTags = [];
      }
    },

    getFilteredTags(text, data) {
      this.filteredTags = data.filter((option) => {
        return option.term.toLowerCase().includes(text.toLowerCase());
      });
    },

    selectSuggestion(suggestion) {
      if (suggestion && suggestion.term) {
        if (!this.tags.includes(suggestion.term)) {
          this.tags.push(suggestion.term);
        }
      } else {
        console.error("Selected suggestion is invalid:", suggestion);
      }
    },
    addNewTag() {
      const tagInput = this.$refs.tagInput;
      if (tagInput && tagInput.inputValue) {
        const tag = tagInput.inputValue.trim();
        if (tag && !this.tags.includes(tag)) {
          this.tags.push(tag);
        }
        tagInput.inputValue = "";
      }
    },
    // addCurrentInputToTags(event) {
    //   console.log(this.inputText);
    //   const tagInput = this.$refs.tagInput;
    //   if (tagInput && tagInput.inputValue) {
    //     const tag = tagInput.inputValue.trim();
    //     if (tag && !this.tags.includes(tag)) {
    //       this.tags.push(tag);
    //     }
    //     tagInput.inputValue = "";
    //   }
    //   // this.quickSearch(); // Call quickSearch after adding the tag
    // },

    async quickSearch() {
      // Wait for a short delay to ensure tags are populated
      await new Promise(resolve => setTimeout(resolve, 200)); // 300ms delay
      this.searchInProgress = false;

      if (this.tags.length === 0 && (this.inputText == null || this.inputText.trim() === "")) {
        console.log("Tags are empty and inputText is empty.");
        this.$buefy.toast.open({
          message: "Please add at least one tag before searching.",
          type: "is-danger",
          duration: 1000, // Duration in milliseconds
        });
        return;
      } else if (this.tags.length === 0 && this.inputText) {
        // console.log("Tags are empty, inputText:", this.inputText);
        // Add inputText to tags if not already present
        if (!this.inputs.includes(this.inputText)) {
          this.inputs.push(this.inputText);
        }
        console.log("Tags are presen", this.inputText);
      } else if (this.tags.length !== 0 && this.inputText) {
        this.tags.push(this.inputText);
        console.log("Input:", this.inputText);
        console.log("Inputs:", this.inputs);
        console.log("Tag:", this.tags);
      }

      // this.tags = [...new Set(this.tags)];
      
      // Merge this.tags and this.inputTexts, ensuring uniqueness
      this.searchTerm = [...new Set([...this.tags, ...this.inputs])];
      
      const locationStore = useLocationStore(); // Access the location store
      const searchStore = useSearchStore(); // Access the search store

      if (!locationStore.currentLocation) {
        console.error("No current location set in the location store.");
        return;
      }
      const searchTermData = this.tags.map((item) => {
        if (Array.isArray(item)) {
          // Handle array type
          return item.join(", ");
        } else if (typeof item === 'object' && item !== null) {
          // Handle object type
          return item.term;
        } else {
          // Handle other types (e.g., string, number)
          // console.log("Item is of type:", typeof item, item);
          return item;
        }
      });
      // Console log the searchTermData for testing
      const uniqueSearchTermData = [...new Set(searchTermData.map(term => term.toLowerCase()))];
      console.log("Search Term Data:", uniqueSearchTermData);
      const searchTermString = uniqueSearchTermData.join(' ');
      const lat = locationStore.currentLocation.lat;
      const lng = locationStore.currentLocation.lng;

      // Uncomment and complete the following block to perform the search
      try {
        const response = await searchStore.quickSearch({
          lat,
          lng,
          term: searchTermString,
        });

        if (response) {
          this.quickSearchResults = response;
          console.log("Quick Search Response:", response);

          this.$router.push({
            name: "searchresult",
            params: {
              searchterm_id: this.quickSearchResults.id,
            },
          });
        }
      } catch (error) {
        console.error("Error during quick search:", error);
      }
    },

    async updateQuickSearchResults() {
      this.$buefy.toast.open("Something happened");
    },
    async setQuickSearchResults() {
      console.log("Quick Search Result Set:");
    },
    async retrieveQuickSearchResults() {
      console.log("Quick Search Result Retrieve:");
    },
    async deleteQuickSearchResults() {
      console.log("Quick Search Result Delete:");
    },

    async preSearchCheck() {
      const authStore = useAuthStore();

      if (!authStore.isAuthenticated || !authStore.user) {
        // Open the LoginModal if not authenticated
        this.$buefy.modal.open({
          parent: this,
          component: LoginModal,
          hasModalCard: true,
          customClass: "custom-class custom-class-2",
          trapFocus: true,
        });
        return; // Exit if not authenticated
      }

      // If authenticated, proceed with the search
      this.confirmCustom(authStore.user.name);

      
    },

    confirmCustom(user) {
      this.$buefy.dialog.confirm({
        title: "Findhub Notice",
        message: `<strong>Dear ${user} </strong>,<br>
        <div class="has-text-justified">
        Please note that Findhub is a search portal that allows you to 
        find products in your local neighborhood stores. We are not responsible 
        for any product-related issues after purchase. As the portal service 
        provider, we value both our customers and business owners.
        <br>
        <br>
        Before making a purchase, ensure that you get the price and offer for your desired product. 
        You can also chat with the supplier for further details. 
        We strongly advise you not to share personal information, such as your mobile number, with the seller.
        We have already taken measures to protect your privacy and will only disclose seller information 
        in case of conflict or issues.<br><br>
        Please note that we do not manage stock or delivery of products. 
        Always check with the seller to confirm the availability of items before making a direct purchase. 
        Currently, we do not provide delivery support, but we are working on introducing pickup and 
        delivery services soon.<br><br>
        <strong>Findhub</strong> is a free service, and we are continuously working to improve user experience. 
        Please support our platform, and if you don't receive an immediate response from a seller, 
        we will notify you if they reply while you're away.
        </div>
        <br>
        For further assistance, please visit our "Contact Us" page.`,
        cancelText: "Disagree",
        confirmText: "Agree",
        type: "is-primary",
        onConfirm: () => {
          this.quickSearch(); // Call the quickSearch function
        },
      });
    },

    handleSelectedTerm(term) {
      console.log(`Search for: ${term}`);
      this.searchInProgress = false;
    },
  },
};

// Example functions to handle different types
function handleArrayType(array) {
  // Logic for handling array type
}

function handleObjectType(object) {
  // Logic for handling object type
}

function handleOtherType(item) {
  // Logic for handling other types
}
</script>

<style scoped>
.suggestion-box {
  border: 1px solid #dbdbdb;
  max-height: 200px;
  overflow-y: scroll;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #00d1b2;
  color: white;
}
</style>
