// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../store"; // Import auth store for checking authentication

import HomeView from "../views/HomeView.vue";
import AboutUs from "../views/pages/AboutUs.vue";
import PrivacyPolicy from "../views/pages/PrivacyPolicy.vue";
import ContactUs from "../views/pages/ContactUs.vue";
import SearchResult from "@/views/SearchResult.vue";
import SupportChat from "@/views/SupportChat.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/search/:searchterm_id",
    name: "searchresult",
    component: SearchResult,
    props: true,
  },
  {
    path: "/support-chat",
    name: "support-chat",
    component: SupportChat,
    meta: { requiresAuth: true }, // Optional if you want to use this in future for restricting routes
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Global navigation guard to check authentication status without blocking navigation
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Call the checkAuthStatus function to update Pinia auth store
  await authStore.checkAuthStatus();

  // Allow navigation without blocking the user
  next();
});

export default router;
