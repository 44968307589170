import { defineStore } from "pinia";
import { MapUtil } from "../utils/map.util";
import { requestFCMToken } from "../services/firebaseService";
import authService from "../services/auth.service"; // Import the auth service
import { useAuthStore } from "./auth"; // Import the auth store

export const useLocationStore = defineStore("location", {
  state: () => ({
    currentLocation: null,
    locationError: null,
    locationRequested: false,
    fcmToken: null,
  }),
  actions: {
    updateSelectedLocation(displayName, lat, lng) {
      this.currentLocation = { displayName, lat, lng };
    },
    setLocationError(error) {
      this.locationError = error;
    },
    async fetchCurrentLocation() {
      if (!("geolocation" in navigator)) {
        this.setLocationError("Geolocation is not supported by this browser.");
        return;
      }

      // Check if latitude and longitude are already saved in local storage
      const existingData =
        JSON.parse(localStorage.getItem("findhub_spa")) || {};
      if (existingData.lat && existingData.lng) {
        // If lat and lng are available, update the location without fetching from Google API
        this.updateSelectedLocation(
          existingData.displayName || "Saved Location", // Use a default display name if not available
          existingData.lat,
          existingData.lng
        );
        this.locationRequested = true; // Mark location as requested
        return; // Exit the function
      }

      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        });
        //eslint-disable-next-line no-undef
        const { latitude, longitude } = position.coords;
        //eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        const response = await geocoder.geocode({
          location: { lat: latitude, lng: longitude },
          language: "en", // Specify English as the language
        });

        if (response.results[0]) {
          const placeInfo = response.results[0];
          const placeData = MapUtil.parseAddressComponents(
            placeInfo.address_components
          );
          const placeName = MapUtil.getLocalDisplayName(placeData); // Get the place name
          this.updateSelectedLocation(
            placeName,
            placeInfo.geometry.location.lat(),
            placeInfo.geometry.location.lng()
          );

          // Store latitude, longitude, and place name in local storage
          const locationData = {
            ...existingData, // Merge existing data
            lat: placeInfo.geometry.location.lat(),
            lng: placeInfo.geometry.location.lng(),
            displayName: placeName, // Add place name to the stored data
          };
          localStorage.setItem("findhub_spa", JSON.stringify(locationData)); // Store updated data
        } else {
          this.setLocationError("No results found");
        }
      } catch (error) {
        let errorMessage;
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage =
              "Location permission denied. Please enable location access in your browser settings.";
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = "Location information is unavailable.";
            break;
          case error.TIMEOUT:
            errorMessage = "The request to get user location timed out.";
            break;
          default:
            errorMessage =
              "An unknown error occurred while trying to get location.";
        }
        this.setLocationError(errorMessage);
        console.error("Geolocation error:", error);
      } finally {
        this.locationRequested = true;
      }
    },
    checkAndRequestLocation() {
      if (!this.locationRequested) {
        this.fetchCurrentLocation();
      }
    },
    async registerFCMToken() {
      console.log("registerFCMToken");
      // Check if the user is authenticated and userData is available
      const authStore = useAuthStore(); // Access the auth store

      const userData = localStorage.getItem("findhub_spa"); // Updated to fetch userData from findhub_spa

      if (!authStore.isAuthenticated || !userData) {
        console.log("User is not authenticated or userData is not available.");
        return; // Exit if conditions are not met
      }

      const token = await requestFCMToken();
      if (token) {
        console.log("FCM Token:", token); // Log the FCM token for debugging
        this.fcmToken = token; // Store the FCM token in the state
        await authService.saveFcmToken(token); // Save the FCM token to the backend
      }
    },
  },
  mutations: {
    SET_FCM_TOKEN(state, token) {
      state.fcmToken = token;
    },
  },
});
