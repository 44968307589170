<template>
  <!-- <div v-if="mobileOS" class="app-promotion-banner">
    <p>Download our app:</p>
    <a
      v-if="mobileOS === 'iOS'"
      href="https://apps.apple.com/your-app-link"
      target="_blank"
    >
      <img src="appstore-icon.png" alt="App Store" />
    </a>
    <a
      v-if="mobileOS === 'Android'"
      href="https://play.google.com/store/apps/details?id=your-app-id"
      target="_blank"
    >
      <img src="playstore-icon.png" alt="Play Store" />
    </a>
  </div> -->
  <div class="container is-fluid findhub_home_container_fluid">
    <div class="columns">
      <div class="column is-full">
        <!-- <h3>User: {{ $currentUser() }}</h3> -->
        <h3 class="title is-1" v-html="welcomeMessage"></h3>
        <!-- {{ edit_1 }} Use v-html to render HTML -->
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="box findhub_home_div_box">
          <b-tabs v-model="activeTab">
            <b-tab-item
              label="Quick Search"
              icon-pack="mdi"
              icon="mdi mdi-magnify"
            >
              <b-field
                ref="locationQuery"
                label="Enter location"
                variant="solo"
              ></b-field>
              <b-button @click="locationStore.fetchCurrentLocation">
                Get My Location
              </b-button>
              <p v-if="locationStore.currentLocation">
                {{ locationStore.currentLocation.displayName }}
              </p>
              <SearchInputCustom />
            </b-tab-item>

            <b-tab-item label="Image" icon-pack="mdi" icon="mdi mdi-image">
              <b-button focused>Add More </b-button>
              <b-tooltip type="is-dark" label="Help text here for explanation">
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
              <b-field>
                <b-upload v-model="dropFiles" multiple drag-drop>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"> </b-icon>
                      </p>
                      <p>Drop your files here or click to upload</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <div class="tags">
                <span
                  v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span>
              </div>
            </b-tab-item>

            <b-tab-item label="Automobiles" icon-pack="mdi" icon="mdi mdi-car">
              <b-field label="Simple">
                <b-select placeholder="Select a name">
                  <option>Car</option>
                  <option>Motorcycle</option>
                  <option>Boat</option>
                </b-select>
              </b-field>
              <b-field label="Loading">
                <b-input placeholder="Loading" loading></b-input>
              </b-field>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
      <div class="column is-hidden-mobile">
        <div class="">
          <HomeCarousel />
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h3>Featured Products</h3>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted , defineAsyncComponent } from "vue";
import { useAuthStore, useLocationStore } from "../store";
import _ from "lodash";
import { MapUtil } from "../utils/map.util";
import SearchInputCustom from "../components/SearchInputCustom.vue";
import HomeCarousel from "@/components/HomeCarousel.vue";
// import LoginModal from "@/components/LoginModal.vue";


const LoginModa = defineAsyncComponent(() => import("@/components/LoginModal.vue"));

const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  return false;
};
const activeTab = ref(0);
const authStore = useAuthStore();
const locationStore = useLocationStore();
const locationQuery = ref(null); // Ensure this is a ref to the input element
const googleAutoComplete = ref(null);
const mobileOS = ref(isMobile());

const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_API_KEY;

const welcomeMessage = computed(() => {
  const currentHour = new Date().getHours(); // {{ edit_1 }} Get the current hour
  let greeting = "Welcome"; // Default greeting

  if (currentHour < 12) {
    greeting = "Good Morning"; // {{ edit_2 }} Morning greeting
  } else if (currentHour < 18) {
    greeting = "Good Afternoon"; // {{ edit_3 }} Afternoon greeting
  } else {
    greeting = "Good Evening"; // {{ edit_4 }} Evening greeting
  }

  const userName =
    authStore.isAuthenticated && authStore.user && authStore.user.name
      ? authStore.user.name // {{ edit_5 }} Get user name
      : "Guest"; // Default name

  return `<span class="findhub_home_welcome_greeting">${greeting}</span><br><span class="findhub_home_welcome_name">${userName}</span>`; // {{ edit_6 }} Use <br> for line break
});

const loadGoogleMapsScript = () => {
  return new Promise((resolve, reject) => {
    window.initMap = resolve;
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${GOOGLE_API_KEY}&language=en&callback=initMap`; // {{ edit_1 }} Add language=en parameter
    script.async = true; // Ensure script is loaded asynchronously
    script.setAttribute("loading", "lazy"); // {{ edit_2 }} Add loading=async attribute
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const initializeGoogleAutocomplete = () => {
  // Ensure locationQuery.value is a valid input element
  if (locationQuery.value && locationQuery.value.$el instanceof HTMLInputElement) {
    // eslint-disable-next-line no-undef
    googleAutoComplete.value = new google.maps.places.Autocomplete(
      locationQuery.value.$el // Access the underlying DOM element
    );
    googleAutoComplete.value.setTypes(["(regions)"]);

    googleAutoComplete.value.addListener("place_changed", () => {
      const placeInfo = googleAutoComplete.value.getPlace();
      if (
        placeInfo &&
        _.has(placeInfo, "address_components") &&
        placeInfo.address_components.length > 0
      ) {
        const placeData = MapUtil.parseAddressComponents(
          placeInfo.address_components
        );
        locationStore.updateSelectedLocation(
          MapUtil.getLocalDisplayName(placeData),
          placeInfo.geometry.location.lat(),
          placeInfo.geometry.location.lng()
        );
      }
    });
  } else {
    console.error("locationQuery is not a valid input element");
  }
};

onMounted(async () => {
  try {
    await loadGoogleMapsScript();
    initializeGoogleAutocomplete();
    await locationStore.registerFCMToken(); // Register FCM token
    if (authStore.isAuthenticated) {
      // Implement your onPostLoginSuccessHandler here if needed
    }
    locationStore.checkAndRequestLocation();
  } catch (error) {
    console.error("Error loading Google Maps script:", error);
  }
});
onMounted(() => {
  mobileOS.value = isMobile();
});
</script>

<style scoped>
.main-content {
  display: flex;
  /* {{ edit_6 }} Flexbox for two-column layout */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 80vh;
  /* Adjust height as needed */
}

.left-column,
.right-column {
  flex: 1;
  /* Equal width for both columns */
}

.findhub_home_welcome_greeting {
  text-transform: uppercase !important;
  /* {{ edit_7 }} Increase font size */
}

.findhub_home_welcome_name {
  /* {{ edit_8 }} Decrease font size */
  color: aquamarine;
}

@media (max-width: 767px) {
  .findhub_home_container_fluid {
    padding: 0 1rem;
  }
  .findhub_home_div_box {
    padding: 0;
  }
}
</style>
