import axios from "axios";

export class FileuploadService {
  static async uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      `/api/seller/file/file-upload`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
    return response.data;
  }

  static getFileUrl(file_id) {
    return "/api/seller/file/" + file_id;
  }
}
