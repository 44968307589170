/* eslint-disable */
import axios from 'axios'
import Cookies from 'universal-cookie' // for handling cookies
import { useAuthStore } from '../store/auth' // Import the Pinia store
const cookies = new Cookies()
const TOKEN_KEY = 'authToken' // Key for storing the token in localStorage

const isDevelopment = process.env.NODE_ENV === 'development' // Check if in development mode

function log(...args) {
  if (isDevelopment) {
    console.log(...args) // Log only in development mode
  }
}

export default {
  // Login method with Sanctum and CSRF handling
  async login(userCredentials) {
    try {
      // Get the CSRF token
      await axios.get("/sanctum/csrf-cookie", {
        withCredentials: true,
      });
      // Perform the login request
      const response = await axios.post(
        "/api/customer/auth/login",
        userCredentials,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token
          },
        }
      );
      log("Login successful", response.data);
      log("Token:", response.data.token);
      // Set the token in localStorage (optional, if your API requires it)
      const token = response.data.token; // Adjust this if your API structure differs
      this.setToken(token);
      return response.data;
    } catch (error) {
      // Pass along the error message from the backend
      if (error.response && error.response.status === 422) {
        throw error.response.data;
      }
      throw error; // Re-throw other errors
    }
  },
  // Logout method
  async logout() {
    try {
      // Get the current token from localStorage
      
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken;;
      const fcmToken = storedData.fcmToken; // Get the FCM token

      log("Logging out...");
      log("FCM Token:", fcmToken);

      // Perform the logout request on the server using fetch
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.post(
        "/api/customer/auth/logout_ck",
        { token: fcmToken }, // Include fcmToken in the payload
        {
          withCredentials: true, // Make sure this is set to true for CSRF
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 204) {
        log("Logout successful");
        // Clear all user-related data from localStorage
        localStorage.removeItem("findhub_spa"); // Remove the specific key
        // Clear all cookies
        this.clearAllCookies(); // Ensure all cookies are cleared
        // Clear the Pinia store
        const authStore = useAuthStore();
        authStore.$reset();
      }

     
      localStorage.removeItem("findhub_spa");
      // Clear all user-related data from localStorage
      // Clear the Pinia store
      const authStore = useAuthStore();
      authStore.$reset();
    } catch (error) {
      log("Error logging out:", error);
      throw error; // Re-throw the error for further handling
    }
  },

  // Remove FCM token
  async removeFcmToken(fcmToken) {
    try {
      const response = await axios.post(
        "/api/customer/notification/delete_fcm_token",
        { token: fcmToken }, // Payload with the FCM token
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Include CSRF token
          },
        }
      );
      log("FCM token removed successfully:", response.data);
      return response.data;
    } catch (error) {
      log(
        "Error removing FCM token:",
        error.response ? error.response.data : error
      );
      throw error; // Re-throw the error for further handling
    }
  },

  // Signup method

  async signup(userData) {
    try {
      await axios.get("/sanctum/csrf-cookie", {
        withCredentials: true,
      });
      const response = await axios.post("/api/customer/auth/signup", userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"),
        },
      });
      log("Signup successful", response.data);
      return response.data;
    } catch (error) {
      log("Signup failed", error.response ? error.response.data : error);
      throw error;
    }
  },

  async policyAccept(email, token) {
    try {
      // await axios.get("/sanctum/csrf-cookie", {
      //     withCredentials: true,
      // });
      const response = await axios.post(
        "/api/customer/auth/accept-policy",
        {
          email: email,
          policy_terms: "This is our new Policy for Customer",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      log("Error accepting policy:", error);
      throw error;
    }
  },

  // Clear client-side cookies
  clearAllCookies() {
    const allCookies = cookies.getAll(); // Get all the cookies
    Object.keys(allCookies).forEach((cookieName) => {
      cookies.remove(cookieName, { path: "/" }); // Remove each cookie
    });
    log("All cookies have been cleared.");
  },
  // Set token in localStorage
  setToken(token) {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // Save token in findhub_spa object
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    storedData.authToken = token; // Store the token under authToken
    this.storeMultipleValues(storedData); // Use the existing function to store the updated object
  },

  setUserData(userData) {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    storedData.userData = userData; // Store the userData under userData key
    this.storeMultipleValues(storedData); 
    // Use the existing function to store the updated object
  },
  // Remove token from localStorage and axios defaults
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
    delete axios.defaults.headers.common["Authorization"];
  },
  // Check authentication status by querying the API
  async checkAuthStatus() {
    const authStore = useAuthStore(); // Get the Pinia store instance
    const isAuthenticated = authStore.isAuthenticated;
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken; // Get the token from findhub_spa
    const googleAuth = localStorage.getItem("hello");
    const userData = storedData.userData; // Retrieve userData from storedData

    log("Checking If Logged in:", isAuthenticated, token, googleAuth, userData);

    if (isAuthenticated || token) {
      try {
        const response = await axios.get("/api/customer/auth/me", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        log("Error checking auth status:", error);
      }
    } else if (googleAuth || userData) {
      try {
        let userDataObj;
        if (googleAuth) {
          log("Google Auth:", googleAuth);
          const googleAuthData = JSON.parse(googleAuth);
          if (googleAuthData.google && googleAuthData.google.access_token) {
            userDataObj = JSON.parse(userData);
          }
        } else if (userData) {
          userDataObj = JSON.parse(userData);
        }

        if (userDataObj && !authStore.user) {
          authStore.setUser(userDataObj);
        }
        return userDataObj;
      } catch (error) {
        log("Error parsing auth data:", error);
      }
    }

    return null;
  },

  async initiateOtpAuth(mobileNumber) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.post(
        "/api/customer/auth/initial_auth",
        { mobile_number: mobileNumber },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      return response;
    } catch (error) {
      log("Error sending OTP:", error.response ? error.response.data : error);
      throw error;
    }
  },

  async validateOtp(requestId, otp) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.put(
        "/api/customer/auth/validate_auth",
        { request_id: requestId, otp: otp },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      return response;
    } catch (error) {
      log(
        "Error validating OTP:",
        error.response ? error.response.data : error
      );
      throw error;
    }
  },
  async attempOAuth2Login(accessToken, type) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.post(
        "/api/customer/auth/signup_oauth",
        {
          token: accessToken,
          type: type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      log("OAuth2 login successful", response.data);
      this.storeOAuth2LoginResponse(response.data);
      return response.data;
    } catch (error) {
      log("OAuth2 login failed", error.response ? error.response.data : error);
      throw error;
    }
  },

  async getToken(requestId) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.get("api/customer/auth/get_token", {
        params: { request_id: requestId },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"),
        },
      });
      // Store the token in local storage if the response is successful
      this.setToken(response.data.token); // Store the token using setToken
      return response; // Return the response
    } catch (error) {
      log("Error getting token:", error.response ? error.response.data : error);
      throw error;
    }
  },

  // Add this new function to store OAuth2 login response
  storeOAuth2LoginResponse(response) {
    const authStore = useAuthStore();
    log("OAuth2 login response:", response);
    // Save user data in Pinia store
    authStore.setUser(response.data);
    authStore.setAuthenticated(true);

    // Save user data in localStorage for persistence
    localStorage.setItem("userData", JSON.stringify(response));
  },

  async getCurrentUser() {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const response = await axios.get("/api/customer/auth/me", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        this.setUserData(response.data); // Save user data using setUserData
        return response.data;
      }
    } catch (error) {
      log("Error fetching current user:", error);
      throw error;
    }
  },

  async saveFcmToken(fcmToken) {
    // Check if the FCM token is already saved
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    if (storedData.fcmToken === fcmToken) {
      log("FCM token already saved, skipping save.");
      return; // Exit if the token is already saved
    }

    // Ensure the existing fcmToken is not overwritten
    if (storedData.fcmToken) {
      log("Existing FCM token found, not overwriting.");
      return; // Exit if an existing token is found
    }

    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true }); // Ensure CSRF token is fetched
      const token = storedData.authToken; // Get the Bearer token from stored data
      const response = await axios.post(
        "/api/customer/notification/token",
        { token: fcmToken }, // Payload with the FCM token
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`, // Use Bearer token for authentication
            "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Include CSRF token
          },
        }
      );
      log("FCM token saved successfully:", response.data);
      storedData.fcmToken = fcmToken; // Update the stored FCM token
      this.storeMultipleValues(storedData); // Store updated values
      return response.data;
    } catch (error) {
      log(
        "Error saving FCM token:",
        error.response ? error.response.data : error
      );
      throw error; // Re-throw the error for further handling
    }
  },

  storeMultipleValues(data) {
    localStorage.setItem("findhub_spa", JSON.stringify(data)); // Store all values under a single key
    log("Stored data:", data);
  },

  // New function to get user data from localStorage

};
